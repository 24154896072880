<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="ma-0 py-0 mt-5">
        <v-btn color="#4caf50" @click="BackToSupplier">
          <v-icon left>mdi-arrow-left</v-icon>
          {{ $vuetify.lang.t("$vuetify.supplier") }}
        </v-btn>
      </v-col>
    </v-row>
    <PageNavigation :items="items" />
    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.Supplier') + titleName"
          icon="mdi-account"
        >
          <v-sheet>
            <v-toolbar-items class="d-flex align-center">
              <v-row>
                <v-col lg="6">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.search')"
                    v-model="search"
                    append-icon="mdi-magnify"
                  />
                </v-col>
              </v-row>
              <v-spacer />
              <v-btn color="green" elevation="2" @click="handleDialog"
                ><v-icon left>mdi-account-plus</v-icon
                >{{ $vuetify.lang.t("$vuetify.add") }}</v-btn
              >
            </v-toolbar-items>

            <tableImg
              :head="headers"
              :data="datas"
              :search="search"
              v-on:edititem="handleDialogEdit"
              v-on:deleteitem="handleDeleteDialog"
            />
          </v-sheet>
        </base-material-card>

        <userCRUD
          :dialog.sync="dialog"
          @handledata="handleSupplierUser"
          :myObj="obj"
        />

        <DialogDelete
          :dialogDelete.sync="dialogDelete"
          :deleteObj="deleteObj"
          @handleDelete="handleDeleteConfirm"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getSupplierAccountList,
  delSupplierAccount,
  upsertSupplierAccount,
} from "@/api/account";
import { supplierInfo } from "@/api/basicdata";
export default {
  name: "supplierAccount",
  components: {
    PageNavigation: () => import("@/components/pageNavigation"),
    tableImg: () => import("@/components/tableImg"),
    userCRUD: () => import("@/components/purchase/supplierUserCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
  },
  data() {
    return {
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.purchase") },
        { text: this.$vuetify.lang.t("$vuetify.supplier") },
        { text: this.$vuetify.lang.t("$vuetify.Supplier") },
      ],
      search: "",
      dialog: false,
      dialogDelete: false,
      datas: [],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.userName"),
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.mobile"),
          value: "mobile",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.email"),
          value: "email",
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.loginTime"),
          value: "login_time",
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.actionState"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      searchForm: {
        key: "",
        supplier_id: "",
        skip: 0,
        limit: 10,
      },
      obj: {
        id: "",
        name: "",
        mobile: "",
        email: "",
        supplier_id: "",
      },
      deleteObj: {
        id: "",
        name: "",
        mobile: "",
        email: "",
        supplier_id: "",
      },
      updataData: {
        id: "",
        name: "",
        mobile: "",
        email: "",
        supplier_id: "",
      },
      wait: false,
      titleName: "",
    };
  },
  methods: {
    getData() {
      getSupplierAccountList(this.searchForm)
        .then((res) => {
          this.datas = [...res.items];
        })
        .catch((err) => console.log(err));
    },
    getInfo() {
      supplierInfo(this.searchForm.supplier_id).then((res) => {
        this.titleName =
          ` (` +
          this.$vuetify.lang.t("$vuetify.company") +
          `: ${res.item.company || res.item.company_en})`;
      });
    },
    handleSupplierUser(obj) {
      this.dialog = false;
      this.updataData.id = obj.id;
      this.updataData.name = obj.name;
      this.updataData.mobile = obj.mobile;
      this.updataData.email = obj.email;
      this.updataData.supplier_id = this.searchForm.supplier_id;
      this.wait = true;
    },
    handleDialog() {
      this.dialog = true;
      this.obj = {
        id: "",
        name: "",
        mobile: "",
        email: "",
        supplier_id: "",
      };
      this.updataData = {
        id: "",
        name: "",
        mobile: "",
        email: "",
        supplier_id: "",
      };
    },
    handleDialogEdit(obj) {
      this.dialog = true;
      this.obj = obj;
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;
      delSupplierAccount(obj.id)
        .then(() => {
          this.datas.splice(this.datas.indexOf(obj), 1);
        })
        .catch((err) =>
          console.log("Supplier Management User Delete Error", err)
        );
    },
    BackToSupplier() {
      this.$router.push("/purchase/supplier");
    },
  },
  mounted() {
    this.searchForm.supplier_id = this.$route.query.id;
    this.getData();
    this.getInfo();
  },
  watch: {
    dialog() {
      this.getData();
    },
    wait: function () {
      if (this.wait == true) {
        upsertSupplierAccount(this.updataData)
          .then(() => {
            this.getData();
            this.wait = false;
          })
          .catch((err) => console.log("New Supplier User: ", err));
      }
    },
  },
};
</script>

<style>
</style>