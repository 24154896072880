import request from '@/utils/request'

export function getAccountList(data) {
  return request({
    url: '/customer_account/list',
    method: 'post',
    data: data
  })
}
export function upsertAccount(data) {
  return request({
    url: '/customer_account/upsert',
    method: 'post',
    data: data
  })
}
export function delAccount(id) {
  return request({
    url: '/customer_account/delete/' + id,
    method: 'delete'
  })
}

export function quertTypeList(data) {
  return request({
    url: '/question_types/list',
    method: 'get',
    data: data
  })
}

export function upsertQuertTypeList(data) {
  return request({
    url: '/question_types/upsert',
    method: 'post',
    data: data
  })
}

export function delQuertTypeList(id) {
  return request({
    url: '/question_types/delete/' + id,
    method: 'delete'
  })
}

export function upsertQuertList(data) {
  return request({
    url: '/question/upsert',
    method: 'post',
    data: data
  })
}

export function QuertList(data) {
  return request({
    url: '/question/list',
    method: 'post',
    data: data
  })
}

export function upsertQuertreply(data) {
  return request({
    url: '/question_reply/upsert',
    method: 'post',
    data: data
  })
}

export function listQuertreply(data) {
  return request({
    url: '/question_reply/list',
    method: 'post',
    data: data
  })
}

export function infoQuert(id) {
  return request({
    url: '/question/info/' + id,
    method: 'get'
  })
}

export function infoQuertrRlation(data) {
  return request({
    url: '/question_relation_people/set',
    method: 'post',
    data: data
  })
}

export function questionAttach(data) {
  return request({
    url: '/question/attach',
    method: 'post',
    data: data
  })
}

export function exportList(data) {
  return request({
    url: '/question/export',
    method: 'post',
    data: data
  })
}

export function upsertMailAccount(data) {
  return request({
    url: '/mail_account/upsert',
    method: 'post',
    data: data
  })
}
export function mailAccountList(data) {
  return request({
    url: '/mail_account/list',
    method: 'post',
    data: data
  })
}

export function mailList(data) {
  return request({
    url: '/mail/list',
    method: 'post',
    data: data
  })
}

export function refulseMail(data) {
  return request({
    url: '/mail/refresh',
    method: 'post',
    data: data
  })
}
export function mailInfo(data) {
  return request({
    url: '/mail/info/' + data,
    method: 'get'
  })
}

export function delMail(data) {
  return request({
    url: '/mail_account/delete/' + data,
    method: 'delete'
  })
}

export function sendMail(data) {
  return request({
    url: '/mail/send',
    method: 'post',
    data: data
  })
}

export function getSupplierAccountList(data) {
  return request({
    url: '/supplier_account/list',
    method: 'post',
    data: data
  })
}
export function upsertSupplierAccount(data) {
  return request({
    url: '/supplier_account/upsert',
    method: 'post',
    data: data
  })
}
export function delSupplierAccount(id) {
  return request({
    url: '/supplier_account/delete/' + id,
    method: 'delete'
  })
}

